import { ComputedRef, computed, ref, watch } from "@nuxtjs/composition-api";
import { ePartnerType } from "@loadsure/core";
import { useCurrentUser } from "./useFireAuth";
import { useSiteConfigurationInject } from "./useSiteConfiguration";
import useRouter from "./useRouter";

const useNavigation = (context) => {
  const {
    email: loggedEmail,
    partnerInfo,
    partnerId: userPartnerId,
    isLoggedIn,
    partnerType,
    hasLoadsureAccess,
    hasNoPartnerAccess,
    isActiveUser,
    isAssuredPartner,
    isLoadsure,
    isPartner,
    isPortal,
    canAccessIntegration
  } = useCurrentUser(context);

  function getQuoteOrBuySubmenu(currentPath: ComputedRef<string>) {
    const { validateMenuPermissionByRoute } = useRouter(context);
    const activeListItemClass = "v-list-item--active";

    const buyPath = "/certificates/buy";
    const selectPolicyHolderPath = "/certificates/selectPolicyHolder";

    const quoteOrBuyClass = computed(() =>
      currentPath.value.includes(buyPath) ||
      currentPath.value.includes(selectPolicyHolderPath)
        ? activeListItemClass
        : undefined
    );

    const isVisibleQuoteOrBuy = isAssuredPartner.value
      ? isActiveUser.value && validateMenuPermissionByRoute(buyPath)
      : !hasNoPartnerAccess.value &&
        isActiveUser.value &&
        validateMenuPermissionByRoute(selectPolicyHolderPath);

    const submenu = {
      title: "Quote or Buy",
      to: isAssuredPartner.value ? buyPath : selectPolicyHolderPath,
      children: undefined,
      visible: isVisibleQuoteOrBuy,
      class: quoteOrBuyClass.value
    };
    return submenu;
  }

  function getSingleShipmentMenu(currentPath: ComputedRef<string>) {
    const { validateMenuPermissionByRoute } = useRouter(context);

    const quoteOrBuySubmenu = getQuoteOrBuySubmenu(currentPath);

    const isVisibleQuoteList =
      loggedEmail.value && validateMenuPermissionByRoute("/quotes/list");
    const isVisibleCerticateList =
      loggedEmail.value && validateMenuPermissionByRoute("/certificates/list");
    const isVisibleFindCertificate =
      loggedEmail.value && validateMenuPermissionByRoute("/certificates/find");
    const isVisibleBulkUpload =
      loggedEmail.value &&
      validateMenuPermissionByRoute("/certificates/bulkUpload");

    const isMenuVisible =
      quoteOrBuySubmenu.visible ||
      isVisibleQuoteList ||
      isVisibleCerticateList ||
      isVisibleFindCertificate ||
      isVisibleBulkUpload;

    const singleShipmentMenu = {
      title: "Single Shipment",
      to: undefined,
      children: [
        quoteOrBuySubmenu,
        {
          title: "Quote List",
          to: "/quotes/list",
          children: undefined,
          visible: isVisibleQuoteList
        },
        {
          title: "Certificate List",
          to: "/certificates/list",
          children: undefined,
          visible: isVisibleCerticateList
        },
        {
          title: "Find Certificate",
          to: "/certificates/find",
          children: undefined,
          visible: isVisibleFindCertificate,
          class: undefined
        },
        {
          title: "Bulk Upload",
          to: "/certificates/bulkUpload",
          children: undefined,
          visible: isVisibleBulkUpload,
          class: undefined
        }
      ],
      visible: isMenuVisible
    };
    return singleShipmentMenu;
  }

  function getAnnualProductsMenu() {
    const { validateMenuPermissionByRoute } = useRouter(context);

    const { allowOceanCargoProduct } = useSiteConfigurationInject();

    const showAnnualCargoMenu = computed(
      () => !isAssuredPartner.value && allowOceanCargoProduct.value
    );

    const isVisibleNewCoverageRequest =
      userPartnerId.value !== undefined &&
      partnerInfo.value?.isEnabled &&
      validateMenuPermissionByRoute("/annualCargo/request");
    const isVisiblePoliciesAndSubmissions =
      !isAssuredPartner.value &&
      validateMenuPermissionByRoute("/annualCargo/list");
    const isVisibleMenu =
      isVisibleNewCoverageRequest || isVisiblePoliciesAndSubmissions;

    const annualProductsMenu = {
      title: "Annual Products",
      to: undefined,
      children: [
        {
          title: "New Coverage Request",
          to: "/annualCargo/request",
          children: undefined,
          visible: isVisibleNewCoverageRequest
        },
        {
          title: "Policies and Submissions",
          to: "/annualCargo/list",
          children: undefined,
          visible: isVisiblePoliciesAndSubmissions
        }
      ],
      visible: showAnnualCargoMenu.value && isVisibleMenu
    };
    return annualProductsMenu;
  }

  function getClaimsMenu() {
    const { validateMenuPermissionByRoute } = useRouter(context);

    const isVisibleFileClaim = validateMenuPermissionByRoute("/claims/find");
    const isVisibleClaimsList =
      validateMenuPermissionByRoute("/claims/listFilter");
    const isVisibleMenu =
      loggedEmail.value && (isVisibleFileClaim || isVisibleClaimsList);

    const claimsMenu = {
      title: "Claims",
      to: undefined,
      children: [
        {
          title: "File Claim",
          to: "/claims/find",
          children: undefined,
          visible: isVisibleFileClaim,
          class: undefined
        },
        {
          title: "Claims List",
          to: "/claims/listFilter",
          children: undefined,
          visible: isVisibleClaimsList
        },
        {
          title: "Annual Products Claims",
          to: "/claims/annual",
          children: undefined,
          visible: isVisibleClaimsList && isLoadsure.value
        }
      ],
      visible: isVisibleMenu
    };
    return claimsMenu;
  }

  function getAddressBookMenu() {
    const { validateMenuPermissionByRoute } = useRouter(context);

    const isVisibleContacts = validateMenuPermissionByRoute("/contacts");
    const isVisibleCarriers =
      isLoggedIn.value && validateMenuPermissionByRoute("/carriers");
    const isVisibleMenu = isVisibleContacts || isVisibleCarriers;

    const addressBookMenu = {
      title: "Address Book",
      to: undefined,
      children: [
        {
          title: "Contacts",
          to: "/contacts",
          children: undefined,
          visible: isVisibleContacts
        },
        {
          title: "Carriers",
          to: "/carriers",
          children: undefined,
          visible: isVisibleCarriers
        }
      ],
      visible: partnerInfo.value && !hasNoPartnerAccess.value && isVisibleMenu
    };
    return addressBookMenu;
  }

  function getAdministrationMenu() {
    const { validateMenuPermissionByRoute } = useRouter(context);

    const isPartnerOrPortal = isPartner.value || isPortal.value;

    const isVisibleSettings =
      isLoadsure.value && validateMenuPermissionByRoute("/portal/settings");
    const isVisiblePartnerSettings =
      isPartner.value && validateMenuPermissionByRoute("/partner/settings");
    const isVisibleMasterPolicies =
      hasLoadsureAccess.value &&
      isAssuredPartner.value &&
      validateMenuPermissionByRoute("/partner/masterPolicies");
    const isVisibleUsers =
      isPartnerOrPortal && validateMenuPermissionByRoute("/users/list");
    const isVisiblePartners =
      isPartnerOrPortal &&
      partnerType.value !== ePartnerType.ASSURED &&
      validateMenuPermissionByRoute("/partners");
    const isVisibleSanctionsChecks =
      isLoadsure.value &&
      validateMenuPermissionByRoute("/admin/supportData/sanctionsChecks");
    const isVisibleTaxes =
      isLoadsure.value && validateMenuPermissionByRoute("/admin/taxes");
    const isVisibleLicensing =
      isLoadsure.value && validateMenuPermissionByRoute("/admin/licensing");
    const isVisibleInvoices =
      validateMenuPermissionByRoute("/partner/invoices");
    const isVisibleMenu =
      isVisibleSettings ||
      isVisiblePartnerSettings ||
      isVisibleMasterPolicies ||
      isVisibleUsers ||
      isVisiblePartners ||
      isVisibleSanctionsChecks ||
      isVisibleTaxes ||
      isVisibleLicensing ||
      isVisibleInvoices;

    const administrationMenu = {
      title: "Administration",
      to: undefined,
      children: [
        {
          title:
            isVisibleSettings && isVisiblePartnerSettings
              ? "Portal Settings"
              : "Settings",
          to: "/portal/settings",
          children: undefined,
          visible: isVisibleSettings
        },
        {
          title:
            isVisibleSettings && isVisiblePartnerSettings
              ? "Partner Settings"
              : "Settings",
          to: "/partner/settings",
          children: undefined,
          visible: isVisiblePartnerSettings
        },
        {
          title: "Master Policies",
          to: "/partner/masterPolicies",
          children: undefined,
          visible: isVisibleMasterPolicies
        },
        {
          title: "Users",
          to: "/users/list",
          childen: undefined,
          visible: isVisibleUsers
        },
        {
          title: "Partners",
          to: "/partners",
          childen: undefined,
          visible: isVisiblePartners
        },
        {
          title: "Sanctions Checks",
          to: "/admin/supportData/sanctionsChecks",
          childen: undefined,
          visible: isVisibleSanctionsChecks
        },
        {
          title: "Taxes",
          to: "/admin/taxes",
          childen: undefined,
          visible: isVisibleTaxes
        },
        {
          title: "Licensing",
          to: "/admin/licensing",
          childen: undefined,
          visible: isVisibleLicensing
        },
        {
          title: "Invoices",
          to: "/partner/invoices",
          childen: undefined,
          visible: isVisibleInvoices
        }
      ],
      visible: partnerInfo.value && isVisibleMenu
    };
    return administrationMenu;
  }

  function getIntegrationMenu() {
    const { validateMenuPermissionByRoute } = useRouter(context);

    const isVisibleApiDocs = validateMenuPermissionByRoute(
      "/integration/apiDocs"
    );
    const isVisibleStyleGuide = validateMenuPermissionByRoute(
      "/integration/styleGuide"
    );
    const isVisiblePlayground = validateMenuPermissionByRoute(
      "/integration/urlLink/playground"
    );
    const isVisibleAppendix = validateMenuPermissionByRoute(
      "/integration/appendix"
    );
    const isVisibleMenu =
      canAccessIntegration.value &&
      (isVisibleApiDocs ||
        isVisibleStyleGuide ||
        isVisiblePlayground ||
        isVisibleAppendix);

    const integrationMenu = {
      title: "Integration",
      to: undefined,
      children: [
        {
          to: "/integration/apiDocs",
          title: "API Docs",
          children: undefined,
          visible: isVisibleApiDocs
        },
        {
          to: "/integration/styleGuide",
          title: "Style Guide",
          children: undefined,
          visible: isVisibleStyleGuide
        },
        {
          to: "/integration/urlLink/playground",
          title: "URL Link Playground",
          children: undefined,
          visible: isVisiblePlayground
        },
        {
          to: "/integration/appendix",
          title: "Appendix",
          children: undefined,
          visible: isVisibleAppendix
        }
      ],
      visible: partnerInfo.value && isVisibleMenu
    };
    return integrationMenu;
  }

  function getUnderwritingMenu() {
    const { validateMenuPermissionByRoute } = useRouter(context);

    const isVisibleHighValueShipment =
      validateMenuPermissionByRoute("/underwriter/list");
    const isVisibleAnnualOceanCargo = validateMenuPermissionByRoute(
      "/underwriter/annualCargo/list/"
    );
    const isVisibleMenu =
      isVisibleHighValueShipment || isVisibleAnnualOceanCargo;

    const underwritingMenu = {
      title: "Underwriting",
      to: undefined,
      children: [
        {
          title: "High Value Shipment",
          to: "/underwriter/list",
          children: undefined,
          visible: isVisibleHighValueShipment
        },
        {
          title: "Annual Ocean Cargo",
          to: "/underwriter/annualCargo/list/",
          children: undefined,
          visible: isVisibleAnnualOceanCargo
        }
      ],
      visible: partnerInfo.value && isVisibleMenu
    };
    return underwritingMenu;
  }

  function getAboutMenu() {
    const { validateMenuPermissionByRoute } = useRouter(context);
    const { email } = useCurrentUser(context);

    const isVisibleAboutLoadsure =
      validateMenuPermissionByRoute("/about/loadsure");
    const isVisibleInternetTradingPolicy = validateMenuPermissionByRoute(
      "/about/internetTradingPolicy"
    );
    const isVisibleMenu =
      (email && isVisibleAboutLoadsure) || isVisibleInternetTradingPolicy;

    const aboutMenu = {
      title: "About",
      to: undefined,
      children: [
        {
          title: "About Loadsure",
          to: "/about/loadsure",
          children: undefined,
          visible: isVisibleAboutLoadsure
        },
        {
          title: "Internet Trading Policy",
          to: "/about/internetTradingPolicy",
          children: undefined,
          visible: isVisibleInternetTradingPolicy
        }
      ],
      visible: isVisibleMenu
    };
    return aboutMenu;
  }

  function getMenu() {
    const { route } = context;
    const currentPath = computed(() => route.value.path);
    const singleShipmentMenu = getSingleShipmentMenu(currentPath);
    const annualProductsMenu = getAnnualProductsMenu();
    const claimsMenu = getClaimsMenu();
    const addressBookMenu = getAddressBookMenu();
    const integrationMenu = getIntegrationMenu();
    const underwritingMenu = getUnderwritingMenu();
    const administrationMenu = getAdministrationMenu();
    const aboutMenu = getAboutMenu();

    const menu = [
      {
        title: "Dashboard",
        to: "/dashboard",
        children: undefined,
        visible: true,
        class: undefined
      },
      singleShipmentMenu,
      annualProductsMenu,
      claimsMenu,
      addressBookMenu,
      integrationMenu,
      underwritingMenu,
      administrationMenu,
      aboutMenu
    ];

    return menu;
  }

  const menuItems = ref(getMenu());

  watch(partnerInfo, (newVal, oldVal) => {
    if (newVal?.id !== oldVal?.id) {
      menuItems.value = getMenu();
    }
  });

  return {
    menuItems
  };
};

export default useNavigation;
